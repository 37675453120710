import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Util from '@/support/utility';
import SigninUtil from '@/support/signinUtil';
import { ServerDataModule } from '@/store/serverData';
import Browser from '@/support/browser';

@Component
export default class LoginComponent extends Vue {

    options = {
    };

    busy = false;

    get serverData() {
        return ServerDataModule.serverData;
    }

    redirectUrl: string = null;

    loading = true;

    created() {
        Debug.setDebugModule('login', this);

        if (this.serverData.isSignedIn) {
            Debug.error('Already signed in');
            Util.showError('Already signed in');
        }

        console.log("login vue created");

        let returnUrl = Browser.getParam('ReturnUrl', '/');
        this.redirectUrl = returnUrl;

        Debug.log('login created', returnUrl, window.location.pathname, window.location.search);

        Debug.log('login', SigninUtil.getProvider(), decodeURIComponent(this.redirectUrl), returnUrl);
    }

    mounted() {
        Debug.log('login mounted', Util.dumpJson(this.serverData));
      // document.body.style.backgroundImage = 'url(/images/White.jpg)';
        console.log("login mounted created");

        if (SigninUtil.redirectToProvider(this.redirectUrl))
            return;

        this.loading = false;
    }

    beforeDestroy() {
        document.body.style.backgroundImage = "";
    }

    onSignIn() {
        this.busy = true;
    }
}
